:root {
    --container-width: #{$container-lg};
}

html, body {
    font-size: $font-size-lg;
}

// Container size
//@include container-sizing($container-lg);
