body.search-content-index,
body.algolia-list,
body.algolia-list-actu {
    --algolia-left-container-width: 250px;
}

body.algolia-list-actu {
    --grid-rows-height: 193px;
}

body.search-content-index,
body.algolia-list {
    .row {
        .algolia-right-container {
            .ais-Hits-item {
                .instant-hit-template {
                    width: 283px;

                    .result-wrapper {
                        .result {
                            height: 450px;
                        }
                    }
                }
            }
        }
    }
}
