$new-account-button: 218px;

.login-container {
    .block-new-customer {
        .block-content {
            .actions-toolbar {
                top: $new-account-button;
            }
        }
    }
}
