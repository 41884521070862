body.contentmanager-contenttype-homepage  {
    .page-main {
        .homepage {
            section {
                &.homepage_recherche {
                    height: 500px;
                }
                + .homepage_actualites {
                    .homepage_actualites-cards {
                        .actu0 {
                            padding: 68px calc(100% - 600px) 44px 100px;
                        }
                        .actu1,
                        .actu2,
                        .actu3 {
                            height: unset;
                            min-height: 600px;
                            display: block;
                        }
                    }
                }

                + .homepage_ctifl {
                    .homepage_ctifl_upper {
                        .ctifl_upper_block {
                            .ct-field,
                            .ctifl_image_container {
                                width: 48%;
                            }
                        }
                    }
                }
            }

            .homepage_social_body {
                .homepage_social_first_col {
                    .homepage-youtube {
                        iframe {
                            height: 500px;
                        }
                    }
                }
                .homepage_social_second_col {
                    margin: 0 0 0 50px;

                    .twitter-container {
                        .twitter-embed {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
