$related-img-height: 207.75px;

body.aw-et-product-page,
body.contentmanager-contenttype-actu,
body.contentmanager-contenttype-projet,
body.contentmanager-contenttype-edition {
    font-size: 14px;

    .page-main {
        max-width: $container-lg;
    }
    .edition-cover {
        width: 350px;
        height: 496px;
    }

    .edition-data-panel {
        min-width: unset;
        width: 55%;
        &-header {
            margin-bottom: 16px;
        }
        .summary {
            .summary-title {
            }
            .subscribe {
                margin-top: 40px;
            }
        }
    }
    .edition-related {
        &-list {
            &-item {
                &.last-related {
                    display: block;
                }
                .subscription-sticker {
                    top: $related-img-height;
                }
                &-body {
                    &-img {
                        &-container {
                            height: $related-img-height;
                        }
                    }
                    &-content {
                        padding-top: 23px;
                    }
                }
            }
        }
    }
    .actu-article,
    .edition-article {
        &-header {
            margin-left: calc((-100vw - 40px + #{$container-lg}) / 2);
            padding-left: calc((100vw + 40px - #{$container-lg}) / 2);
        }
    }
}

.edition-progress-bar {
    top: 117px;
}

.page-wrapper:has(.welcome-wrapper) .edition-progress-bar {
    top: calc(117px + #{$welcome-banner-height});
}

body.aw-et-product-page,
body.contentmanager-contenttype-projet,
body.edition-page {
    .edition__header-image {
        width: 1160px;
        height: 290px;
        object-fit: cover;
        margin-bottom: 0;
    }
}

$pouet: 25vw;

.edition-publication-container {
    .edition-publication {
        min-height: calc(#{$pouet} / 210 * 297);
        .edition-panel-picture {
            margin-inline: calc(#{$container-lg} / 20);
            max-width: $pouet;
            height: calc(#{$pouet} / 210 * 297);
        }
    }
}
